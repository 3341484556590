import React from "react";
import { NavLink } from "react-router-dom";

import "./styles.scss";

const Logo = props => {
  return (
    <NavLink className={`brand ${props.theme}`} to="/">
      &nbsp;
    </NavLink>
  );
};

export default Logo;
