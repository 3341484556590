import React from 'react'
import { BrowserRouter, Route, Switch } from "react-router-dom";
import ScrollIntoView from "./scrollintoview"

import Header from '../components/header'
import Footer from '../components/footer'
import Home from '../components/home'
import About from '../components/about'
import Portfolio from '../components/portfolio'
import Contact from '../components/contact'

    


export default props => (
    <BrowserRouter basename="/">
        <div>
          <ScrollIntoView>
            <Header/>
            <Switch>
                <Route path="/" component={Home} exact />
                <Route path="/about" component={About} />
                <Route path="/work/:id?"
                       render={props => (
                           <Portfolio {...props} /> // pass the sub-routes down to keep nesting
                       )}
                />
                <Route path="/contact" component={Contact} />
            </Switch>
            <Footer />
          </ScrollIntoView>
        </div>
    </BrowserRouter>
)

