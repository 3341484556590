import React from 'react'
import { Fade } from 'react-reveal'

import './styles.scss'

export default props => (
    <div className="contact">
        <Fade>
            <div className="webdoor webdoor-contact">&nbsp;</div>
        </Fade>

        <div className="main-content main-content-contact container-fluid">
            <div className="row row-page-content">
                <div className="col-md-6 col-xs-12 col-subtitle">
                    <div className="subtitle">
                        <Fade left>
                            <h2>Got a Project?</h2>
                            <h2><label>Let's Talk</label></h2>
                        </Fade>
                    </div>
                </div>
                <div className="col-md-6 col-xs-12 col-contact">

                    {/* WHAT WE DO LIST */}

                    <div className="row row-details">
                        <div className="col-md-12 col-xs-12 col-left">

                            <Fade>
                                <strong>Location <label>&</label> Meetings</strong>
                                <p>Rua das Fontaínhas, 74 / 1300-257 / Portugal - Lisbon</p>
                            </Fade>

                            <Fade>
                                <a href="https://goo.gl/maps/QCyeZCsWTF5qWLZu9" target="_blank">View on map</a>
                            </Fade>

                        </div>
                    </div>

                    <div className="row row-address">
                        <div className="col-md-6 col-xs-12 col-left">
                            <Fade>
                                <strong>Talk to <label>us</label></strong>
                                <p>info@brandscape.pt<br/>
                                    +351 214 210 178</p>
                            </Fade>
                        </div>
                        <div className="col-md-6 col-xs-12 col-left">
                            <Fade>
                                <strong>Join our <label>team</label></strong>
                                <p>areyou@brandscape.pt<br/>
                                +351 214 210 178</p>
                            </Fade>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
)