const listProjects = [
  {
    id: 1,
    name: "1 Fight",
    description:
      "Strategy, Branding, Store Environment, Web, Digital Marketing, Print",
    cover: "1fight/caselogo.png",
    text_1:
      "The challenge was to create an innovative fitness concept, based on fight modalities, with a high positioning and not to be confused with a fighting training facility.",
    text_2:
      "The answer is 1Fight, a project_link that involved everything from brand strategy and naming to the store environment, branding, digital, etc.",
    bg_webdoor: "1fight/caseintro.png",
    bg_4: "1fight/caselogo.png",
    bg_8: "1fight/casewebsite.png",
    bg_8_2: "1fight/casecartoes.png",
    bg_12: "1fight/casedesporto.png",
    model: 1
  },
  {
    id: 2,
    name: "Alegria Plaza",
    description: "Concept, Branding, Graphic Design, Print, Video, Digital",
    cover: "alegriaplaza/case_alegriaplaza01.png",
    text_1:
      "A special city, a rare location with a unique view and a very refined architecture were the motto for a branding project that resulted in Alegria Plaza. Our proposal was a contemporary, simple and refined image for a very exquisite real estate development.",
    text_2:
      "A brand identity as full of character as the city of which it is a part. Together with this,  we developed all the materials to support a very bold and up-to-date promotion and sales strategy.",
    bg_webdoor: "alegriaplaza/case_alegriaplaza05.png",
    bg_4: "alegriaplaza/case_alegriaplaza01.png",
    bg_4_2: "alegriaplaza/case_alegriaplaza04.png",
    bg_8: "alegriaplaza/case_alegriaplaza02.png",
    bg_12: "alegriaplaza/case_alegriaplaza03.png",
    model: 2
  },
  {
    id: 3,
    name: "Verney",
    description: "E-commerce, Digital Marketing",
    cover: "verney/case_verney05.png",
    text_1:
      "A Portuguese brand specializing in cruelty-free footwear, for which we developed an entire e-commerce strategy. The on-line store, along with the development of the design and information architecture, are the most visible aspects of this project.",
    text_2:
      "Interpreting the behavior of this target, a strongly developing niche, and creating a contemporary and intuitive design, were some of the challenges we had to overcome.",
    bg_webdoor: "verney/case_verney04.png",
    bg_4: "verney/case_verney05.png",
    bg_8: "verney/case_verney01.png",
    bg_8_2: "verney/case_verney02.png",
    bg_12: "verney/case_verney03.png",
    model: 3
  },
  {
    id: 4,
    name: "Ita",
    description: "Branding, Graphic design, Print",
    cover: "ita/case_ita03.png",
    text_1:
      "ITA is a B2B telecommunications solutions provider operating in the Angolan market, with a very high positioning, that is experiencing a moment of both (r)evolution and expansion.",
    text_2:
      "This image, idealized for this great moment in the company’s history, represents not only the new offer or even its positioning but the new attitude and posture towards the market and its competitors.",
    bg_webdoor: "ita/case_ita05.png",
    bg_4: "ita/case_ita03.png",
    bg_8: "ita/case_ita01.png",
    bg_8_2: "ita/case_ita04.png",
    bg_12: "ita/case_ita02.png",
    model: 3
  },
  {
    id: 5,
    name: "Candando",
    description: "Strategy, Branding, Photography, Contents, Print",
    cover: "candando/case_candando04.png",
    text_1:
      "A chain of supermarkets in Luanda, Angola, which aims to extend itself throughout Angola. They have been a client since 2016, in a partnership for which the main project is Candando Magazine.",
    text_2:
      "We also work on product development projects, for the private label brands of this supermarket, as well as seasonal digital campaigns. Two examples of this are the “Barista” coffee brand, as well as a website dedicated to Christmas catalogs.",
    bg_webdoor: "candando/case_candando05.png",
    bg_4: "candando/case_candando04.png",
    bg_8: "candando/case_candando06.png",
    bg_8_2: "candando/case_candando01.png",
    bg_12: "candando/case_candando02.png",
    model: 1
  },
  {
    id: 6,
    name: "Botanica",
    description: "Branding, Graphic Design, Print",
    cover: "botanica/case_botanica01.png",
    text_1:
      "The growth of tourism, in the city of Setúbal, and consequently a larger, more demanding and refined audience, justified the existence of a more unique restaurant concept in town.",
    text_2:
      "To meet the expectations of the owner and the menu, we came out with a brand identity that is very gastronomic, with humor and a strong personality... just like the restaurant itself!",
    bg_webdoor: "botanica/case_botanica04.png",
    bg_4: "botanica/case_botanica01.png",
    bg_4_2: "botanica/case_botanica05.png",
    bg_8: "botanica/case_botanica03.png",
    bg_12: "botanica/case_botanica02.png",
    model: 2
  },
  {
    id: 7,
    name: "Sovena",
    description: "Digital marketing",
    cover: "sovena/case_sovena04.png",
    text_1:
      "A brand and a Portuguese product that has become a worldwide reference. Developed content capable of helping the consumer to interpret the richness of Oliveira da Serra products.",
    text_2:
      "Social media management, to increase the proximity between the target and the brand and contribute to better commercial performance.",
    bg_webdoor: "sovena/case_sovena05.png",
    bg_4: "sovena/case_sovena04.png",
    bg_8: "sovena/case_sovena01.png",
    bg_8_2: "sovena/case_sovena02.png",
    bg_12: "sovena/case_sovena03.png",
    model: 3
  },
  {
    id: 8,
    name: "Bimby",
    description: "Strategy, Photography, Contents, Print",
    cover: "bimby/case_bimby05.png",
    text_1:
      "Editorial design and content marketing are the main focus in our working relationship with Vorwerk, the company that owns the brands Thermomix and Bimby.",
    text_2:
      "The monthly magazine and the Bimby books, projects published in Portugal, are benchmarks. Besides Portugal, we developed all of the contents and editorial design for the books published in Mexico and the United Kingdom.",
    bg_webdoor: "bimby/case_bimby05.png",
    bg_4: "bimby/case_bimby04.png",
    bg_8: "bimby/case_bimby01.png",
    bg_8_2: "bimby/case_bimby03.png",
    bg_12: "bimby/case_bimby02.png",
    model: 1
  },
  {
    id: 9,
    name: "Primeira Folha",
    description: "Strategy, Branding, Packaging, Photography, Web, Print",
    cover: "primeirafolha/case_primeirafolha01.png",
    text_1:
      "Microleafs harvested in the first days of life. Since they are harvested so early, they concentrate many more nutrients and even more flavor.",
    text_2:
      "This was the starting point of a cutting-edge project in the food industry, which took us from strategy to branding, from product design to packaging, and  to other marketing disciplines.",
    bg_webdoor: "primeirafolha/case_primeirafolha05.png",
    bg_4: "primeirafolha/case_primeirafolha02.jpg",
    bg_8: "primeirafolha/case_primeirafolha01.png",
    bg_8_2: "primeirafolha/case_primeirafolha04.png",
    bg_12: "primeirafolha/case_primeirafolha03.png",
    model: 3
  },
  {
    id: 10,
    name: "Luís Simões",
    description: "Web, Digital Marketing, Content, Print",
    cover: "luissimoes/casesocial_media-copy.png",
    text_1:
      "Thinking, conceiving, designing and programming the new Luis Simões website was a work we took on with pride, but also with a lot of caution.",
    text_2:
      "A job that involved, beyond the development component, a whole new approach to the brand in terms of image and communication tone. The amount of information, the features and the extension of the site proved to be a challenge, both gratifying and complex.",
    bg_webdoor: "luissimoes/casefoto.png",
    bg_4: "luissimoes/casesocial_media-copy.png",
    bg_8: "luissimoes/casewebsite.png",
    bg_8_2: "luissimoes/casesocial_media.png",
    bg_12: "luissimoes/casewebsite_02.png",
    model: 3
  },
  {
    id: 11,
    name: "Lease Plan",
    description: "Strategy, Content, Print, Web",
    cover: "leaseplan/case_leaseplan05.png",
    text_1:
      "The classic model of a magazine in paper format, which has a digital dimension, with daily update, in the form of a content hub.",
    text_2:
      "A fascinating project that puts us at the forefront of content creation in the areas of mobility and automotive innovation.",
    bg_webdoor: "leaseplan/case_leaseplan05.png",
    bg_4: "leaseplan/case_leaseplan03.png",
    bg_8: "leaseplan/case_leaseplan02.png",
    bg_8_2: "leaseplan/case_leaseplan04.png",
    bg_12: "leaseplan/case_leaseplan01.png",
    model: 1
  },
  {
    id: 12,
    name: "Nobre Terra",
    description: "Branding, Graphic Design, Print",
    cover: "nobreterra/case_nobreterra02.jpg",
    text_1:
      "When two high school teachers invited us to create a traditionally produced brand of liquor, we felt the challenge was beyond reproach.",
    text_2:
      "From strategy to brand creation, packaging, design to business consulting, we did everything to see this project grow. And boy, did it grow!",
    bg_webdoor: "nobreterra/case_nobreterra01.jpg",
    bg_4: "nobreterra/case_nobreterra02.jpg",
    bg_8: "nobreterra/case_nobreterra03.jpg",
    bg_4_2: "nobreterra/case_nobreterra05.jpg",
    bg_12: "nobreterra/case_nobreterra04.jpg",
    model: 2
  },
  // {
  //     id: 13,
  //     name : 'Bling',
  //     description : 'Branding, Graphic Design, Print',
  //     cover : 'bling/case_bling04.jpg',
  //     text_1: 'A challenge coming from a sector as competitive as the electronics sector was very exciting for our team. From a very strong and emotional concept, we started with the branding strategy, to design a range of products with contemporary design, carefully produced packaging, well finished and with excellent perceived quality.',
  //     text_2: 'A challenge coming from a sector as competitive as the electronics sector was very exciting for our team. From a very strong and emotional concept, we started with the branding strategy, to design a range of products with contemporary design, carefully produced packaging, well finished and with excellent perceived quality.',
  //     bg_webdoor:'bling/case_bling05.jpg',
  //     bg_4:'bling/case_bling01.jpg',
  //     bg_8:'bling/case_bling02.jpg',
  //     bg_4_2:'bling/case_bling04.jpg',
  //     bg_12:'bling/case_bling03.jpg',
  //     model: 2,
  // },
  {
    id: 14,
    name: "Uniball",
    description: "Strategy, Branding, Digital Marketing",
    cover: "uniball/case_uniball05.png",
    text_1:
      "We are proud to support the local operation of Uni-ball, from the Uni Mitsubishi Pencil Company, famous worldwide. We have the challenge to activate different targets through digital marketing, social media and brand design.",
    text_2:
      "From the strategy to the digital marketing tools we want to emphasize the outstanding quality of Uni-ball product portfolio, a combination of quality, innovation and design to deliver an exceptional writing experience every time.",
    bg_webdoor: "uniball/case_uniball05.png",
    bg_4: "uniball/case_uniball01.png",
    bg_8: "uniball/case_uniball03.png",
    bg_8_2: "uniball/case_uniball06.png",
    bg_12: "uniball/case_uniball02.png",
    model: 3
  },
  {
    id: 15,
    name: "Pet Online",
    description: "Strategy, Branding, E-commerce, Digital Marketing",
    cover: "petonline/case_petonline05.png",
    text_1:
      "A Portuguese online store for loving pets and caring owners, who want only the best. Pet Online it's a special and exquisite shop that brings together all the necessary products and services, from food and grooming products, to insurance and online vet, to those who really love their pets.",
    text_2:
      "Concept, brand identity, ecommerce, digital performance and social media management, in a fun and exclusive brand to work with/for.",
    bg_webdoor: "petonline/case_petonline05.png",
    bg_4: "petonline/case_petonline01.png",
    bg_8: "petonline/case_petonline03.png",
    bg_8_2: "petonline/case_petonline02.png",
    bg_12: "petonline/case_petonline06.png",
    model: 3
  },
  {
    id: 16,
    name: "VidaFit",
    description:
      "Strategy, Branding, Store Environment, Digital Marketing, Print",
    cover: "vidafit/case_vidafit05.png",
    text_1:
      "A Saudi female only premium gym chain in which we were involved from the first moment. Concept, brand identity, interior design and marketing and communication consultancy.",
    text_2:
      "The communication strategy implemented included Digital marketing (website, social media, etc.), launch and promotional campaigns together with a corporate magazine, all developed and implemented together with the in-house marketing team.",
    bg_webdoor: "vidafit/case_vidafit05.png",
    bg_4: "vidafit/case_vidafit01.png",
    bg_8: "vidafit/case_vidafit02.png",
    bg_8_2: "vidafit/case_vidafit06.png",
    bg_12: "vidafit/case_vidafit03.png",
    model: 3
  }
];

export default listProjects;
