import React from "react";

import "./styles.scss";

import Logo from "../logo";
import SocialLinks from "../SocialLinks";

const Footer = () => {
  const path = document.location.pathname.split("/");
  const footerclass = `container-fluid footer-site ${path[path.length - 1]}`;

  return (
    <div id="footer" className={footerclass}>
      <div className="row">
        <div className="col footer-brand">
          <Logo theme="dark" />
        </div>
        <div className="col footer-address footer-address_one">
          <div className="content">
            <strong>BRANDSCAPE PORTUGAL</strong>
            <p>
              Rua das Fontaínhas, 74 <br />
              1300-257 Lisboa - Portugal <br />
              T +351 21 421 01 78 <br />
              info@brandscape.pt
            </p>
          </div>
        </div>
        <div className="col footer-address footer-address_two">
          <div className="content">
            <strong>BRANDSCAPE ANGOLA</strong>
            <p>
              Edifício TENSAI BUSINESS CENTER
              <br />
              Talatona - Zona CS8 - Gleba GU22
              <br />
              T +351 21 421 01 78
              <br />
              info@brandscape-angola.com
            </p>
          </div>
        </div>
        <div className="col footer-social">
          <div className="content">
            <SocialLinks />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
