import React, {Component} from 'react';
import axios from 'axios';
import { Fade } from 'react-reveal';
import './styles.scss';

import bgForm from './../../img/bg-form-aboutus.jpg';

const API_PATH = './api/contact.php';

const get_random_number = () => {
        return Math.floor(Math.random() * 6 + 1);
    }

// All numbers are equal
let numberOne = get_random_number(); 
let numberTwo = get_random_number(); 

// run this loop until numberOne is different than numberThree
do {
    numberOne = get_random_number();
} while(numberOne === numberTwo);



export default class About extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)

        this.state = {
            name: '',
            email: '',
            phone: '',
            description: '',
            mailSent: false,
            error: null,
            bg1 : require('./../../img/page-about-us-' + numberOne + '.jpg'),
            bg2 : require('./../../img/page-about-us-' + numberTwo + '.jpg'),
        }
    }


    handleSubmit(e) {
        e.preventDefault();
        axios({
            method: 'post',
            url: `${API_PATH}`,
            headers: { 'content-type': 'application/json' },
            data: this.state
        })
        .then(result => {
            this.setState({
                mailSent: result.data.sent
            })
        })
        .catch(error => this.setState({ error: error.message }));
    }

    render()
    {
        return (
            <div className="about">
                <div className="main-content main-content-about">

                    <div className="row row-page-title">
                        <div className="col-md-7 col-xs-12 col-page-title top-md top-xs">
                            <div className="content">
                            <Fade left>
                                <div className="title">
                                    <h1>We</h1><h1>Are</h1>
                                </div>
                            </Fade>
                            <Fade right>
                                <div className="subtitle">
                                    <h2>Restless</h2>
                                    <h2>Innovators</h2>
                                    <h2>Brandscape</h2>
                                </div>
                            </Fade>
                            </div>
                        </div>
                        <Fade>
                            <div className="col-md-5 col-xs-12 bg_img"
                                 style={{backgroundImage: `url(${this.state.bg2})`}}>&nbsp;
                            </div>
                        </Fade>
                    </div>

                    <div className="row row-description">
                        <Fade>
                            <div className="col-md-7 col-xs-12 bg_img" style={{backgroundImage: `url(${this.state.bg1})`}}>&nbsp;</div>
                        </Fade>

                        <Fade>
                            <div className="col-md-5 col-xs-12 col-description">
                                <div className="description">
                                    <p>We experience the joy of delving into the details of a briefing, savoring the creative energy of a project, studying strategies, analyzing ratios, clarifying and setting goals, drawing, shooting or filming, testing and retesting. We dare to do things differently. We live outside the box and take risks anticipating the future.</p>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    <div className="row row-page-subtitle">
                        <div className="col-md-12 col-xs-12 col-page-subtitle center-md center-xs">
                            <Fade bottom>
                                <h2>What <label>we</label></h2>
                                <h2>do best</h2>
                            </Fade>
                        </div>
                    </div>

                    <div className="row row-we-do-list">
                        <Fade left>
                            <div className="col-lg-4 col-md-5 item">
                                <div className="title">
                                    <h3>Digital</h3>
                                    Digital
                                </div>
                                <div className="content">
                                    <p>You’re ready to tell the world about your brilliant business, perfect product or crucial campaign. But how do you reach your audience? The answer is digital marketing.</p>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-4 col-md-5 item">
                                <div className="title">
                                    <h3>Branding</h3>
                                    Branding
                                </div>
                                <div className="content">
                                    <p>How can your brand support the ambitions of your business? We can help you to create a robust brand identity and a strategy by understanding why your business exists, how you work and what are your future plans.</p>
                                </div>
                            </div>
                        </Fade>
                    </div>
                    <div className="row row-we-do-list">

                        <Fade left>
                            <div className="col-lg-4 col-md-5 item">                                
                                <div className="title">
                                    <h3>Publishing</h3>
                                    Publishing
                                </div>
                                <div className="content">
                                    <p>Our in-house team of experienced and talented designers will provide the right technical solution to deliver results. As technology evolves and changes, we will work with you to adapt and stay ahead.</p>
                                </div>
                            </div>
                        </Fade>

                        <Fade right>
                            <div className="col-lg-4 col-md-5 item">
                                {/* <img src={iconPackaging} alt="Packaging"/> */}
                                <div className="title">
                                    <h3>Packaging</h3>
                                    Packaging
                                </div>
                                <div className="content">
                                    <p>A well-designed package can complement or even enhance the product it was designed to carry. Good design isn’t just about the product – it’s about good packaging as well.</p>
                                </div>
                            </div>
                        </Fade>
                    </div>

                    <form id="contact-form" onSubmit={this.handleSubmit} method="POST">
                        <div className="row row-work-with-us" style={{backgroundImage: `url(${bgForm})`}}>
                            <Fade top>
                                <div className="col-md-12 col-xs-12 col title"><h3>Talk <span>to us</span></h3></div>
                            </Fade>

                            <Fade>
                                <div className="col-md-4 col-xs-12 col">
                                    <input type="text"
                                           placeholder="Your name"
                                           id="name"
                                           required
                                           value={this.state.name}
                                           onChange={e => this.setState({ name: e.target.value })}
                                    />
                                </div>
                            </Fade>
                            <Fade>
                                <div className="col-md-4 col-xs-12 col">
                                    <input type="email"
                                           placeholder="Email"
                                           id="email"
                                           value={this.state.email}
                                           onChange={e => this.setState({ email: e.target.value })}
                                           required/>
                                </div>
                            </Fade>
                            <Fade>
                                <div className="col-md-4 col-xs-12 col">
                                    <input type="tel"
                                           placeholder="Phone"
                                           id="phone"
                                           value={this.state.phone}
                                           onChange={e => this.setState({ phone: e.target.value })}
                                           required/>
                                </div>
                            </Fade>
                            <Fade>
                                <div className="col-md-12 col-xs-12 col">
                                    <textarea placeholder="Project description"
                                              id="description"
                                              value={this.state.description}
                                              onChange={e => this.setState({ description: e.target.value })}
                                              required/>
                                </div>
                            </Fade>
                            <Fade bottom>
                                <div className="col-md-12 col-xs-12 col center-md center-sm center-xs">
                                    <button type="submit">Send</button>
                                        {this.state.mailSent &&<div>Thank you for contacting us.</div>}
                                </div>
                            </Fade>
                        </div>
                    </form>
                </div>
            </div>
        )
    }
}