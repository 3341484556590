import React, { Component } from "react";

import ProjectLink from "../project_link";

import listProjects from "../../main/util";

import "./styles.scss";

function shuffle(sourceArray) {
  for (var i = 0; i < sourceArray.length - 1; i++) {
    var j = i + Math.floor(Math.random() * (sourceArray.length - i));

    var temp = sourceArray[j];
    sourceArray[j] = sourceArray[i];
    sourceArray[i] = temp;
  }
  return sourceArray;
}

export default class ProjectList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      list: shuffle(listProjects),
      limit: 6
    };
  }

  componentDidMount() {
    document.addEventListener("scroll", () => {
      const wrappedElement = document.getElementsByTagName("body");

      if (
        wrappedElement[0].getBoundingClientRect().bottom <=
        window.innerHeight + 300
      ) {
        this.setState({ limit: this.state.limit + 6 });
      }
    });
  }

  render() {
    return this.state.list
      .slice(0, this.state.limit)
      .map((item, index) => (
        <ProjectLink item={item} key={index} pathTo={index} />
      ));
  }
}
