import React from "react";
import ReactDOM from "react-dom";

import TagManager from "react-gtm-module";

import "./scss/main.scss";
import "./../node_modules/flexboxgrid-sass/flexboxgrid.scss";
import "./scss/fonts.scss";
import "./scss/colors.scss";
import "./scss/tipography.scss";

import App from "./main/app";
import * as serviceWorker from "./serviceWorker";

const tagManagerArgs = {
  gtmId: "GTM-TBSTHGN"
};

TagManager.initialize(tagManagerArgs);

ReactDOM.render(<App />, document.getElementById("root"));
serviceWorker.unregister();
