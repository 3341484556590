import React from "react";
import { NavLink } from "react-router-dom";

export const BgImage = props => {
  const imageUrl = require(`../../img/${props.imageName}`);
  return (
    <div
      className={props.className}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      {props.children}
    </div>
  );
};

export const BgImageList = props => {
  const imageUrl = require(`../../img/${props.imageName}`);
  return (
    <NavLink
      to={props.to}
      className={props.className}
      style={{ backgroundImage: `url(${imageUrl})` }}
    >
      {props.children}
    </NavLink>
  );
};
