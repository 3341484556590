import React, { Component } from "react";

import "./styles.scss";

import Logo from "../../components/logo";
import Menu from "../../components/menu";

export default class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      didScroll: true,
      lastScrollTop: 0,
      navClass: "",
      isOnTop: true
    };

    this.handleScroll = this.handleScroll.bind(this);
    this.hideHeader = this.hideHeader.bind(this);
    this.showHeader = this.showHeader.bind(this);
    this.showHeader = this.showHeader.bind(this);
    this.getDocHeight = this.getDocHeight.bind(this);
    this.hasScrolled = this.hasScrolled.bind(this);
    this.getPath = this.getPath.bind(this);
  }

  getPath() {
    return document.location.pathname.split("/");
  }

  getDocHeight() {
    return Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  hideHeader() {
    this.setState({
      navClass: ""
    });
  }

  showHeader() {
    this.setState({
      navClass: "active"
    });
  }

  hasScrolled() {
    const st = window.scrollY;
    const delta = 1;
    const navbarHeight = document.getElementById("header").offsetHeight;

    // Make sure they scroll more than delta
    if (Math.abs(this.state.lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > this.state.lastScrollTop && st > navbarHeight) {
      // Scroll Down
      this.hideHeader();
    } else {
      // Scroll Up
      if (st < this.getDocHeight()) {
        if (st < 50) {
          this.setState({
            isOnTop: true,
            navClass: ""
          });
        } else {
          this.setState({
            isOnTop: false
          });

          if (st > 150) {
            this.setState({
              navClass: "active"
            });
          }
        }
      }
    }

    this.setState({
      lastScrollTop: st
    });
  }

  handleScroll(event) {
    this.setState({
      didScroll: true
    });
    this.hasScrolled();
  }

  render() {
    const isOnTop = this.state.isOnTop ? "is-on-top" : "";
    const path = this.getPath();
    const theme = path.includes("work") && path[2] ? "light" : "dark";
    const headerClass = `header-site ${path} ${this.state.navClass} ${isOnTop}`;

    return (
      <div id="header" className={headerClass}>
        <nav className="navbar navbar-expand-lg">
          <Logo theme={theme} />
          <Menu theme={theme} />
        </nav>
      </div>
    );
  }
}
