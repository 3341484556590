import React from "react";
import { BgImageList } from "./../BgImage";

import "./styles.scss";

const imgPortfolioPath = "cases/";

const ProjectLink = props => {
  return (
    <BgImageList
      className="col-md-4 col-sm-4 col-xs-12 project project-single"
      imageName={imgPortfolioPath + props.item.cover}
      to={`/work/${props.pathTo}`}
    >
      <div className="content">
        <h2>{props.item.name}</h2>
        <h3>{props.item.description}</h3>
      </div>
    </BgImageList>
  );
};

export default ProjectLink;
