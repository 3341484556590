import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Fade } from "react-reveal";

import "./styles.scss";
import { BgImage } from "./../BgImage";
import listProjects from "./../../main/util";
import ProjectList from "../project_list";

const imgPortfolioPath = "cases/";

class Portfolio extends Component {
  constructor(props) {
    super(props);

    this.showModel = this.showModel.bind(this);
    this.backItem = this.backItem.bind(this);
    this.nextItem = this.nextItem.bind(this);

    this.state = {
      models: {
        1: item => (
          <div className="model">
            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_12}
                className="col-md-12 col-xs-12 bg_img bg_img_big"
              />
            </div>

            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_4}
                className="col-md-4 col-xs-12 bg_img"
              />
              <BgImage
                imageName={imgPortfolioPath + item.bg_8}
                className="col-md-8 col-xs-12 bg_img"
              />
            </div>

            <div className="row row-list">
              <div className="col-md-4 col-xs-12 col-description">
                <div className="description">
                  <p>{item.text_2}</p>
                </div>
              </div>
              <BgImage
                imageName={imgPortfolioPath + item.bg_8_2}
                className="col-md-8 col-xs-12 bg_img"
              />
            </div>
          </div>
        ),

        2: item => (
          <div className="model">
            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_4}
                className="col-md-4 col-xs-12 bg_img"
              />
              <BgImage
                imageName={imgPortfolioPath + item.bg_8}
                className="col-md-8 col-xs-12 bg_img"
              />
            </div>

            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_12}
                className="col-md-12 col-xs-12 bg_img bg_img_big"
              />
            </div>

            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_4_2}
                className="col-md-4 col-xs-12 bg_img"
              />

              <div className="col-md-8 col-xs-12 col-description">
                <div className="description">
                  <p>{item.text_2}</p>
                </div>
              </div>
            </div>
          </div>
        ),

        3: item => (
          <div className="model">
            <div className="row row-list">
              <div className="col-md-4 col-xs-12 col-description order-sm-2">
                <div className="description">
                  <p>{item.text_2}</p>
                </div>
              </div>
              <BgImage
                imageName={imgPortfolioPath + item.bg_8}
                className="col-md-8 col-xs-12 bg_img order-sm-1"
              />
            </div>

            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_8_2}
                className="col-md-8 col-xs-12 bg_img"
              />
              <BgImage
                imageName={imgPortfolioPath + item.bg_4}
                className="col-md-4 col-xs-12 bg_img"
              />
            </div>

            <div className="row row-list">
              <BgImage
                imageName={imgPortfolioPath + item.bg_12}
                className="col-md-12 col-xs-12 bg_img bg_img_big"
              />
            </div>
          </div>
        )
      },
      item: !isNaN(this.props.match.params.id)
        ? listProjects[this.props.match.params.id]
        : listProjects[0],
      currentItemIndex: !isNaN(this.props.match.params.id)
        ? this.props.match.params.id
        : 0
    };
  }

  showModel() {
    return this.state.models[this.state.item.model](this.state.item);
  }

  backItem() {
    return listProjects[this.state.currentItemIndex - 1] ? (
      <NavLink
        className="back-link"
        to={"/work/" + (this.state.currentItemIndex - 1)}
      >
        <span>Back</span>
      </NavLink>
    ) : (
      ""
    );
  }

  nextItem() {
    const index = parseFloat(this.state.currentItemIndex) + 1;
    return listProjects[index] ? (
      <NavLink className="next" to={"/work/" + index}>
        <span>Next</span>
      </NavLink>
    ) : (
      ""
    );
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      item:
        nextProps.match.params.id !== undefined
          ? listProjects[nextProps.match.params.id]
          : "",
      currentItemIndex: !isNaN(nextProps.match.params.id)
        ? nextProps.match.params.id
        : 0
    });
  }

  render() {
    if (this.props.match.params.id !== undefined) {
      return (
        <div className="portfolio">
          <BgImage
            imageName={imgPortfolioPath + this.state.item.bg_webdoor}
            className="webdoor webdoor-portfolio this.state.item.name bg_img"
          />

          <div className="main-content main-content--portfolio-project">
            <Fade top>
              <div className="row row-title">
                <div className="col-md-6 col-xs-10">
                  <div className="title">
                    <h1>{this.state.item.name}</h1>
                    <h3>{this.state.item.description}</h3>
                  </div>
                </div>
              </div>
            </Fade>
            <Fade bottom>
              <div className="row row-description">
                <div className="col-md-6 col-xs-10">
                  <div className="description">
                    <p>{this.state.item.text_1}</p>
                  </div>
                </div>
              </div>
            </Fade>
          </div>

          <Fade>{this.showModel()}</Fade>

          <Fade bottom>
            <div className="bottom-projects text-center">
              {this.backItem()} <label>/</label> {this.nextItem()}
            </div>
          </Fade>
        </div>
      );
    } else {
      return (
        <div className="portfolio">
          <div className="main-content main-content--portfolio">
            <Fade top>
              <div className="row row-title">
                <div className="title title-prev">
                  <h1>Having worked with so many brands,</h1>
                  <h1>
                    we can proudly say – <label>we are ready for more.</label>
                  </h1>
                </div>
              </div>
            </Fade>
          </div>

          {/* PROJECT LIST */}
          <Fade>
            <div className="row">
              <ProjectList />
            </div>
          </Fade>
        </div>
      );
    }
  }
}

export default Portfolio;
