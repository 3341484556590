import React, { useEffect, useState } from "react";

import ProjectList from "../project_list";

import { Fade } from "react-reveal"; // Importing Zoom effect
import BackgroundSlider from "react-background-slider";
import Typewriter from "typewriter-effect";

import "./styles.scss";

const importAll = require => require.keys().map(img => require(img));

const imagesDesktop = importAll(
  require.context("../../img/desktop_img", false, /\.(png|jpe?g|svg)$/)
);
const imagesMobile = importAll(
  require.context("../../img/mobile_img", false, /\.(png|jpe?g|svg)$/)
);

const titles = [
  ["Olá!!", "#fc5000"],
  ["Hei!", "#05ff91"],
  ["Ciao!", "#72ffe8"],
  ["Hallo!", "#7800bb"],
  ["Hello!", "#ff189f"],
  ["Bonjour!", "#ff5955"],
  ["Hallå!", "#9580ff"],
  ["Hok!", "#ffd100"],
  ["¡Holla!", "#244cff"]
];

const subtitles = [
  "Relevant",
  "Humans",
  "Changing",
  "Eager",
  "Here",
  "Contents",
  "True",
  "Ready"
];

const getRandomFrom = array => array[Math.floor(Math.random() * array.length)];

const Home = () => {
  const [currentTitle, setCurrentTitle] = useState(getRandomFrom(titles));
  const [width, setWidth] = useState(window.innerWidth);

  const scrollToProjects = () => {
    const element = document.getElementById("projectsList");
    const rect = element.getBoundingClientRect();

    window.scrollTo({
      top: rect.top,
      behavior: "smooth"
    });
  };

  useEffect(() => {
    return () => {};
  });
  useEffect(() => {
    const intervalTitles = setInterval(() => {
      setCurrentTitle(getRandomFrom(titles));
    }, 5000);

    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      clearInterval(intervalTitles);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="webdoor webdoor-home">
        {width >= 768 ? (
          <BackgroundSlider
            images={imagesDesktop}
            duration={3}
            transition={1}
          />
        ) : (
          <BackgroundSlider images={imagesMobile} duration={3} transition={1} />
        )}

        <h1>
          <span style={{ color: currentTitle[1] }}>{currentTitle[0]}</span>
        </h1>

        <h2>We Are</h2>

        <h2 className="typed">
          <Typewriter
            options={{
              strings: subtitles,
              autoStart: true,
              loop: true
            }}
          />
        </h2>

        <span id="scrollto" className="scrollto" onClick={scrollToProjects}>
          See projects
        </span>
      </div>
      <div className="home main-content">
        <div className="row title-home">
          <div className="title col-12">
            <Fade left>
              <h1>We Are</h1>
            </Fade>
            <Fade right>
              <h2>Brandscape</h2>
            </Fade>
          </div>
        </div>

        <div className="row">
          <div className="col-md-6 col-xs-10 description">
            <Fade>
              <p>
                Our commitment is to help you achieve your goals. We rely on the
                technical and creative capacity of our teams and on a close
                relationship with our customers to always go one step beyond.
                Authenticity, transparency and honesty are values we cherish and
                that must be present in everything we do.
              </p>
            </Fade>
          </div>
        </div>

        {/* PROJECT LIST */}
        <div id="projectsList" className="row">
          <ProjectList />
        </div>
      </div>
    </>
  );
};

export default Home;
