import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./styles.scss";

import SocialLinks from "../../components/SocialLinks";

export default class Menu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNavMenu: false
    };

    this.toggleNavMenu = this.toggleNavMenu.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
  }

  toggleNavMenu() {
    const { showNavMenu } = this.state;
    this.setState({ showNavMenu: !showNavMenu });
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);

    const el = document.getElementsByClassName("brand");
    for (let i = 0; i < el.length; i++) {
      el[i].addEventListener("click", this.handleScroll);
    }
  }

  handleScroll() {
    const { showNavMenu } = this.state;
    if (showNavMenu) this.setState({ showNavMenu: !showNavMenu });
  }

  render() {
    return (
      <div id="menu" className={this.props.theme}>
        <div
          onClick={this.toggleNavMenu}
          className={this.state.showNavMenu ? "call-nav active" : "call-nav"}
        >
          <span>&nbsp;</span>
        </div>

        <div className={this.state.showNavMenu ? "menu active" : "menu"}>
          <div className="content">
            <ul className="menu-links">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/about"
                  onClick={this.toggleNavMenu}
                >
                  About us
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/work"
                  onClick={this.toggleNavMenu}
                >
                  Work
                </NavLink>
              </li>
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  to="/contact"
                  onClick={this.toggleNavMenu}
                >
                  Contact
                </NavLink>
              </li>
            </ul>

            <SocialLinks />
          </div>
        </div>
      </div>
    );
  }
}
