import React from "react";

import "./styles.scss";

const SocialLinks = props => (
  <div className="social-links">
    <div className="link">
      <a
        href="https://www.linkedin.com/company/brandscape-lifestylecreativity/"
        target="_blank"
        rel="noopener noreferrer"
      >
        In
      </a>
    </div>
    <div className="link">
      <a
        href="https://www.facebook.com/brandscape.pt/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Fb
      </a>
    </div>
    <div className="link">
      <a
        href="https://www.instagram.com/brandscape_agency/?hl=en"
        target="_blank"
        rel="noopener noreferrer"
      >
        Insta
      </a>
    </div>
  </div>
);

export default SocialLinks;
